export default [{
  descrRaggruppamento: 'Abitazione',
  tipologia_tipo: 1,
  raggruppamento: 'A'
}, {
  descrRaggruppamento: 'Cantina',
  tipologia_tipo: 1,
  raggruppamento: 'C'
}, {
  descrRaggruppamento: 'deposito/magazzino/capannone',
  tipologia_tipo: 1,
  raggruppamento: 'D'
}, {
  descrRaggruppamento: 'fabbricato',
  tipologia_tipo: 1,
  raggruppamento: 'F'
}, {
  descrRaggruppamento: 'garage/box/autorimessa',
  tipologia_tipo: 1,
  raggruppamento: 'G'
}, {
  descrRaggruppamento: 'immobile agricolo',
  tipologia_tipo: 1,
  raggruppamento: 'R'
}, {
  descrRaggruppamento: 'immobile commerciale',
  tipologia_tipo: 1,
  raggruppamento: 'E'
}, {
  descrRaggruppamento: 'immobile industriale',
  tipologia_tipo: 1,
  raggruppamento: 'I'
}, {
  descrRaggruppamento: 'locale/laboratorio',
  tipologia_tipo: 1,
  raggruppamento: 'L'
}, {
  descrRaggruppamento: 'struttura assistenziale/pubblica/ospedaliera',
  tipologia_tipo: 1,
  raggruppamento: 'S'
}, {
  descrRaggruppamento: 'Terreno',
  tipologia_tipo: 1,
  raggruppamento: 'T'
}, {
  descrRaggruppamento: 'varie',
  tipologia_tipo: 1,
  raggruppamento: 'V'
}]
